import SafetyAndHealthApiCommon from '@jetCommon/api/safety-and-health.js';

export default class SafetyAndHealthApi extends SafetyAndHealthApiCommon {
    uploadCertificate(id, data) {
        return this.apiPost(`${id}/upload_certificate/`, data);
    }

    updateCourseStatus(id, course_status) {
        return this.apiPost(`${id}/update_course_status/`, {course_status});
    }
}
